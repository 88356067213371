/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import '../styles/index.css';
import Layout from "../components/Layout";
import ProductList from "../components/ProductList";
import {Link} from "gatsby";
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import types from '../data/types.json';

const Product = ({pageContext}) => {
    const product = pageContext.product;
    const group = pageContext.group;
    return (
        <>
            <Layout location={product.path} type="detail">
                <div>
                    <div id="img_cara" className="double">
                        <Carousel stopAutoPlayOnHover infinite={product.images.length > 1 ? true : false} centered dots
                                  autoPlay={2000} animationSpeed={1000}>
                            {product.images.map((el, i) => {
                                return (
                                    <img key={"slide" + i} src={el} alt={product.name} className={"img465"}/>
                                )
                            })}
                        </Carousel>
                        <div>
                            <h1>{product.name}</h1>
                            <h3>Характеристики</h3>
                            <p dangerouslySetInnerHTML={{__html: product.description}}></p>
                            <p className="mgb2x">
                                <a href="#" data-link="to_more" className="txtbtn">Подробности</a>
                            </p>
                            <h3 style={{display: product.types.length > 0 ? "block" : "none"}}>Подходит для</h3>
                            <p style={{display: product.types.length > 0 ? "block" : "none"}}>
                                {
                                    product.types.map((el, index) => {
                                        return (
                                            <span key={"productPicto" + index}
                                                  className={"picto conseil_" + el.toLowerCase().replace(" ", "_") + " active"}>
                                                {types[el]}
                                            </span>
                                        );
                                    })
                                }
                            </p>
                            <p>
                                <Link to={"/contact-us"} className="btn">Запросить информацию</Link>
                            </p>
                        </div>
                    </div>
                    <div data-anchor="to_more">
                        <h3 className="underlined"><strong>Подробное описание</strong></h3>
                        <div className="need_space">
                            <p dangerouslySetInnerHTML={{__html: product.detail}}></p>
                        </div>
                        {product.link ?
                            <p>
                                <a href={product.link} className={"btn"} target="_blank">Скачать брошюру</a>
                            </p> : ""}
                    </div>
                </div>
                <section id="associated" className="content">
                    <div>
                        <div className="ctr">
                            <span className="h4 nmgb">Продукты</span>
                            <h2 className="h1 lmg">Другие наши {group.name}</h2>
                        </div>
                        <div className={`quadra ${group.color}`}>
                            <ProductList group={group}/>
                        </div>
                        <p className="ctr mgt"><Link to={group.path} className={"btn"}>Посмотреть все</Link></p>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Product;
